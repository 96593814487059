<template>
  <div :class="classes.component" @click="delegateLinks" v-html="text" />
</template>

<script>
import { delegateLinks } from '@/mixins/delegate-links'
import { mapState } from 'vuex'

export default {
  inheritAttrs: false,
  mixins: [delegateLinks],
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['currentLanguage']),
    classes() {
      return {
        component: [
          this.$style.component,
          this.currentLanguage != 'de' ? this.$style.otherLang : ''
        ]
      }
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @extend %base-bodytext;

  transition: color var(--mlong) ease;

  // override declarations set in mixin when lang changes
  &.otherLang {
    color: var(--white);

    a {
      &:hover {
        color: var(--red);
        background-color: var(--white);
      }

      &:not([href^='mailto:']) {
        border-color: var(--white);
      }
    }
  }
}
</style>
