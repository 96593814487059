<template>
  <div :class="$style.component" v-if="columns.length">
    <div :class="$style.inner">
      <repeater-matrix-item
        v-for="(item, index) in columns"
        :item="item"
        :width="width"
        :key="`row-${index}`"
      />
    </div>
  </div>
</template>

<script>
import RepeaterMatrixItem from '@/components/repeater-matrix-item'

export default {
  components: { RepeaterMatrixItem },
  props: {
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    width: {
      type: String,
      default: '100'
    }
  }
}
</script>

<style lang="scss" module>
.component {
  // padding: var(--blank-line) var(--sub-spacing-vertical) 0;
  padding-top: var(--blank-line);

  &:first-child {
    padding-top: calc(var(--blank-line) * 2);
  }

  &:last-child {
    padding-bottom: calc(var(--blank-line) * 2);
  }
}

.inner {
  @media (min-width: $medium) {
    display: flex;
    margin-left: calc(var(--gutter) * -2);
  }
}
</style>
