<template>
  <main :class="classes.view">
    <template v-if="page.fields">
      <base-bodytext :text="page.fields.lead" :class="$style.lead" />
      <accordion-list :items="page.children" ref="list" />
    </template>
  </main>
</template>

<script>
import PageService from '@/services/page'
import { metaInfo } from '@/mixins/meta-info'
import BaseBodytext from '@/components/base-bodytext'
import AccordionList from '@/components/accordion-list'
import EventBus from '@/event-bus'
import { mapState } from 'vuex'
import { gsap } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default {
  components: { AccordionList, BaseBodytext },
  mixins: [metaInfo],
  data() {
    return {
      page: {},
      loading: true,
      currentLink: {
        height: null,
        level: 0
      }
    }
  },
  computed: {
    ...mapState([
      'currentLanguage',
      'headerHeight',
      'introHidden',
      'isDesktop'
    ]),
    classes() {
      return {
        view: [
          this.$style.view,
          this.currentLanguage != 'de' ? this.$style.otherLang : ''
        ]
      }
    },
    scrollOffset() {
      // TODO: get dynamic value of item height for different levels and multiple parent links
      const levelOffset =
        this.currentLink.level > 0 ? this.currentLink.height : 0
      const headerOffset = this.isDesktop ? this.headerHeight : 0
      return levelOffset + headerOffset
    }
  },
  methods: {
    scrollToTarget(id) {
      if (id === null) {
        gsap.to(window, {
          duration: 0.8,
          scrollTo: '#top',
          ease: 'power1.easeInOut'
        })
      } else {
        gsap.to(window, {
          duration: 0.8,
          scrollTo: {
            y: '#id-' + id,
            offsetY: this.scrollOffset
          },
          ease: 'power1.easeInOut'
        })
      }
    },
    sendFooterData() {
      const footer = {
        address: this.page.fields.address,
        contact: this.page.fields.contact
      }
      EventBus.$emit('home-loaded', footer)
    }
  },
  async created() {
    this.page = await PageService.get({ id: 1 })
    this.loading = false
    this.sendFooterData()
  },
  mounted() {
    EventBus.$on('on-complete', payload => {
      // pass emitted id from accordion-item into scroll to
      this.scrollToTarget(payload)
    })
    EventBus.$on('current-link', payload => {
      // pass current link from accordion-link to evaluate scroll offset
      this.currentLink.height = payload.height
      this.currentLink.level = payload.level
    })
  },
  watch: {
    introHidden() {
      // this.$nextTick(() => {
      EventBus.$emit('list-height', this.$refs.list.$el.offsetHeight)
      // })
    }
  }
}
</script>

<style lang="scss" module>
// .view {}

.lead {
  @extend %fs-lead;

  margin-bottom: calc(var(--blank-line) / 1.5);
  hyphens: auto;
}

::selection {
  background-color: var(--selection-color-positive);

  .otherLang & {
    color: var(--red);
    background-color: var(--selection-color-negative);
  }
}
</style>
