<template>
  <li :class="$style.component" :id="`id-${item.meta.id}`">
    <accordion-link
      :item="item"
      :index="index"
      :is-current="isCurrent"
      :level="level"
      :has-expand="hasExpand"
      @on-click="emitCurrent"
    />

    <transition @enter="accordionEnter" :css="false">
      <div :class="classes.content" :key="isCurrent" ref="content">
        <template v-if="hasContent">
          <accordion-row
            v-for="(row, index) in item.fields.content_rows"
            :key="`row-${index}`"
            :row="row"
          />
        </template>

        <!-- TODO: stagger transition für children? -->
        <accordion-list
          v-if="hasChildren"
          :items="item.children"
          :level="level + 1"
          :current-id="currentId"
        />
      </div>
    </transition>
  </li>
</template>

<script>
import AccordionLink from '@/components/accordion-link'
import AccordionRow from '@/components/accordion-row'
import { gsap } from 'gsap'
import EventBus from '@/event-bus.js'

export default {
  name: 'accordion-item', // necessary to make calling a component from within itself possible
  components: {
    AccordionLink,
    AccordionList: () => import('@/components/accordion-list'), // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    AccordionRow
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    currentId: {
      type: Number,
      default: null
    },
    level: {
      type: Number,
      required: true
    },
    prevHeight: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      duration: 0.45,
      delay: 0.35,
      clickedId: null,
      itemHeightPrevious: 0
      // isOpen: false
    }
  },
  computed: {
    classes() {
      return {
        content: [
          this.$style.content,
          this.$style['level-' + this.level],
          this.isCurrent ? this.$style.isCurrent : '',
          'accordion-content',
          'level-' + this.level
        ]
      }
    },
    isCurrent() {
      return this.item.meta.id === this.currentId
    },
    hasChildren() {
      return this.item.children && this.item.children.length ? true : false
    },
    showChildren() {
      return this.hasChildren && this.isCurrent
    },
    hasContent() {
      return this.item.fields.content_rows &&
        this.item.fields.content_rows.length
        ? true
        : false
    },
    hasExpand() {
      return this.hasChildren || this.hasContent
    }
  },
  methods: {
    emitCurrent(payload) {
      this.clickedId = payload
      // send to parent element
      this.$emit('on-click', payload)
    },
    accordionEnter(el, done) {
      // debugger
      // if (!this.isOpen) {
      if (this.isCurrent) {
        // only for animating height
        // const enter = gsap.fromTo(
        gsap.fromTo(
          el,
          this.duration,
          {
            height: 0
          },
          {
            height: 'auto',
            onComplete: this.onAnimationEnd
          }
        )

        // Based on: https://greensock.com/forums/topic/12241-get-end-value-of-classname-tween/
        // el.style.opacity = 0
        // enter.progress(1)
        // // NOTE: always send height of item of level 0 , also and especially if level is larger than 0
        // const parentContent = el.closest('.accordion-content.level-0')
        // // console.log(parentContent)
        // const parentHeight = parentContent.offsetHeight
        // // NOTE: add height of li to this as there is a pattern visible that the calc height is always 43px less than it should be
        // const calculatedHeight = parentHeight - this.prevHeight
        // // console.log('current full content', parentHeight)
        // // console.log('prev height', this.prevHeight)
        // // console.log('calculated height', calculatedHeight)

        // // emit to default to animate box to enable scrolling to top
        // EventBus.$emit('current-item-height', calculatedHeight)

        // enter.progress(0)
        // // emit to parent to pass previous height to all its children
        // this.$emit('previous-height', calculatedHeight)
        // el.style.opacity = 1

        // only for opacity
        gsap.fromTo(
          el,
          this.duration,
          {
            opacity: 0
          },
          {
            opacity: 1,
            delay: this.delay
          }
        )
      } else {
        // el.style.position = 'absolute'
        gsap.fromTo(
          el,
          this.duration,
          {
            // height: 'auto'
            height: el.offsetHeight
          },
          {
            height: 0
          }
        )
        // debugger

        // gsap.to(el, this.duration, {
        //   height: 0
        // })

        gsap.fromTo(
          el,
          this.duration / 2,
          {
            opacity: 1
          },
          {
            opacity: 0
          }
        )
        // el.style.position = 'static'
      }

      // this.isOpen = !this.isOpen

      done()
    },
    onAnimationEnd() {
      // emit currently clicked id from child to home after animation is finished
      EventBus.$emit('on-complete', this.clickedId)
    }
  },
  mounted() {
    // Set height with JS (instead of CSS) in order to get closing animation of accordion working
    this.$refs.content.style.height = 0

    // Simulate click on accordion item to open it on page load
    // TODO: This only works over two levels
    if (
      this.$route.name === this.item.meta.id ||
      this.$route.meta.parent === this.item.meta.id
    ) {
      this.$emit('on-click', this.item.meta.id)
      // TODO: temp fix to scroll to accordion item only after intro is hidden
      setTimeout(() => {
        EventBus.$emit('on-complete', this.item.meta.id)
      }, 2500)
    }
  }
}
</script>

<style lang="scss" module>
// .component {}

.content {
  // height: 0;
  overflow: hidden;
  opacity: 0;

  &:global(.level-1) {
    padding-left: var(--sub-spacing-vertical);
  }

  // &.isCurrent {
  //   // position: sticky;
  //   padding-top: 0;

  //   &.level-1 {
  //     // also take border of parent element into account
  //     padding-top: calc(var(--blank-line) * 3 + 3px);

  //     // @media (min-width: $medium) {
  //     //   padding-top: calc(var(--blank-line) + 3px);
  //     // }
  //   }
  // }
  // position: relative;
}

// &.isCurrent {
//   position: sticky;
//   top: 0;

//   &.level-1 {
//     // also take border of parent element into account
//     top: calc(var(--blank-line) * 1.5 + 3px);

//     @media (min-width: $medium) {
//       top: calc(var(--blank-line) * 1.8);
//     }
//   }
// }
</style>
