<template>
  <div
    v-sticky="isCurrent"
    sticky-offset="stickyOffset"
    :sticky-z-index="stickyZIndex"
    :class="classes.component"
    :key="currentLanguage"
    on-stick="onStick"
    ref="link"
  >
    <a
      v-if="item.fields.external_url"
      :href="item.fields.external_url"
      :class="$style.button"
      target="_blank"
      rel="noopener"
    >
      <div :class="$style.inner">
        <span :class="$style.external" />
        <span v-html="item.fields.title" :class="$style.title" />
      </div>
    </a>
    <a
      v-else
      :href="`#id-${item.meta.id}`"
      :class="$style.button"
      @click.prevent="setCurrent"
    >
      <div :class="$style.inner">
        <span v-if="isCurrent" :class="$style.open" />
        <span v-else :class="$style.close" />
        <span v-html="item.fields.title" :class="$style.title" />
      </div>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EventBus from '@/event-bus'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isCurrent: {
      type: Boolean,
      required: true
    },
    level: {
      type: Number,
      required: true
    },
    hasExpand: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      currentId: null,
      isSticky: false
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'isDesktop', 'headerHeight']),
    classes() {
      return {
        component: [
          this.$style.component,
          this.$style['level-' + this.level],
          this.hasExpand ? this.$style.hasExpand : '',
          this.currentLanguage != 'de' ? this.$style.otherLang : '',
          this.isCurrent ? this.$style.isCurrent : '',
          this.isSticky ? this.$style.isSticky : ''
        ]
      }
    },
    stickyOffset() {
      const levelOffset =
        this.level === 0 ? 0 : this.$refs.link.offsetHeight + 2 // NOTE: + 2 to accommodate for border
      const headerOffset = this.isDesktop ? this.headerHeight : 0
      return {
        // TODO: make offset reactive (see https://github.com/mehwww/vue-sticky-directive/issues/13)
        top: levelOffset + headerOffset
      }
    },
    stickyZIndex() {
      return 3 - this.level
    }
    // itemIndex() {
    //   if (this.index === 0) return 0
    //   return this.index - 1
    // }
  },
  methods: {
    setCurrent() {
      this.currentId = this.isCurrent ? null : this.item.meta.id
      this.$emit('on-click', this.currentId)
      this.$store.commit('setCurrentId', {
        currentId: this.currentId
      })

      if (this.isCurrent) {
        const urlSegments = this.item.meta.url.replace(/\/$/, '').split('/')
        urlSegments.pop()
        const parentUrl = urlSegments.join('/') + '/'
        window.history.replaceState(null, null, parentUrl)
      } else {
        window.history.replaceState(null, null, this.item.meta.url)
        // send actively clicked link to home to define scroll offset for scrolling to target
        const currentLink = {
          height:
            this.level === 0
              ? this.$refs.link.offsetHeight
              : this.$refs.link.offsetHeight + 2, // NOTE: + 2 to accommodate for border
          level: this.level,
          index: this.index
        }
        EventBus.$emit('current-link', currentLink)
      }
    },
    onStick(el) {
      this.isSticky = el.sticked
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @extend %fw-regular;

  padding: calc(var(--blank-line) / 4) 0;
  cursor: pointer;
  border-bottom: solid 3px var(--red);

  &.isSticky {
    background-color: var(--white);

    &.otherLang {
      background-color: var(--red);
    }
  }

  &.level-1 {
    @extend %fw-light;

    padding-left: var(--sub-spacing-vertical);
    border-width: 1px;
  }

  &.otherLang {
    border-color: var(--white);
  }
}

.button {
  position: relative;
  display: block;
  width: 100%;
  padding-right: var(--gutter);
  text-align: left;
}

.inner {
  @extend %fs-title;

  display: inline-block;
  color: var(--red);
  text-transform: uppercase;

  .level-1 & {
    color: var(--black);
  }

  .otherLang & {
    color: var(--white);
  }
}

.title {
  padding-left: var(--gutter);
}

.open,
.close,
.external {
  position: absolute;
  top: -0.05em;
  left: 0;
}

.open {
  .hasExpand & {
    &::before {
      content: '–';
    }
  }
}

.close {
  .hasExpand & {
    &::before {
      content: '+';
    }
  }
}

.external {
  top: 0.125em;

  &::after {
    content: '\2197';
  }
}

// locally define text color for selection, globally set in home.vue
::selection {
  color: var(--white);
}
</style>
