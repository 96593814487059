<template>
  <div :class="$style.component" :style="itemWidth">
    <component :is="type" :item="item" />
  </div>
</template>

<script>
export default {
  components: {
    RepeaterMatrixTypeBody: () =>
      import('@/components/repeater-matrix-type-body'),
    RepeaterMatrixTypeImage: () =>
      import('@/components/repeater-matrix-type-image'),
    RepeaterMatrixTypeImageWithBody: () =>
      import('@/components/repeater-matrix-type-image-with-body'),
    RepeaterMatrixTypeList: () =>
      import('@/components/repeater-matrix-type-list')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '100'
    }
  },
  computed: {
    type() {
      const type = this.item.type.replace(/\s+|_+/g, '-').toLowerCase()
      return `repeater-matrix-${type}`
    },
    itemWidth() {
      return { width: this.width + '%' }
    }
  }
}
</script>

<style lang="scss" module>
.component {
  &:not(:last-of-type) {
    padding-bottom: var(--blank-line);
  }

  @media (min-width: $medium) {
    padding-left: calc(var(--gutter) * 2);

    &:not(:last-of-type) {
      padding-bottom: 0;
    }
  }
}
</style>
