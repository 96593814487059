<template>
  <repeater-matrix :columns="row.content_columns" :width="itemWidth" />
</template>

<script>
import RepeaterMatrix from '@/components/repeater-matrix'
import { mapState } from 'vuex'

export default {
  components: { RepeaterMatrix },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['isDesktop']),
    itemWidth() {
      if (!this.isDesktop) return
      return this.row.row_layout.value
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @media (min-width: $medium) {
    display: flex;
    justify-content: space-between;
  }
}
</style>
