<template>
  <ul :class="classes.component">
    <accordion-item
      v-for="(item, index) in items"
      :key="item.meta.id"
      :item="item"
      :index="index"
      :current-id="clickedId"
      :level="level"
      :prev-height="prevHeight"
      @on-click="setCurrent"
      @previous-height="setPrevHeight"
    />
  </ul>
</template>

<script>
import AccordionItem from '@/components/accordion-item'

export default {
  name: 'accordion-list', // necessary to make calling a component from within itself possible
  components: {
    AccordionItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    currentId: {
      type: Number,
      default: null
    },
    level: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      clickedId: null,
      prevHeight: 0
    }
  },
  computed: {
    classes() {
      return {
        component: [this.$style.component, this.$style['level-' + this.level]]
      }
    }
  },
  methods: {
    setCurrent(payload) {
      this.clickedId = payload
    },
    setPrevHeight(payload) {
      this.prevHeight = payload
    }
  }
}
</script>

<style lang="scss" module>
.component {
  padding-bottom: var(--blank-line);

  @media (min-width: $medium) {
    padding-bottom: calc(var(--blank-line) * 2);
  }
}

.level {
  // &-0 {}
  // &-1 {}
}
</style>
